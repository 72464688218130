import React from "react";
import { StaticImage } from "gatsby-plugin-image"

//A component that displays the author of a blog post in tailwindcss

export default function AuthorBox() {

    return (
        <div className="bg-gray-100 w-full p-5">
            <div className="lg:text-left lg:flex lg:flex-row w-auto lg:mb-4">
                <StaticImage src="../../images/avatar.png" alt="Lucas Rosvall" className="sm:!block rounded-full !w-28 !h-28 my-auto" />
                <div className="w-auto lg:ml-6 lg:max-w-2xl">
                <h3 className="!mt-1 lg:!mt-3 !text-2xl">Skriven av Lucas R</h3>
                <p>Mitt namn är Lucas och jag är en passionerad naturentusiast! För mig finns det inget bättre än att ge mig ut på vandringar i det fria, kasta ut fiskelinan för att fånga dagens middag eller laga ihop en gourmetmåltid på mitt gaskök ute i naturen.</p>
                </div>
               </div>
        </div>
    );
}
