import React from "react";
import { Link } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"

export default function RelatedPosts({ posts=[],dateOptions }) {

  return (
    <div className="blogPost">
      <h4>Du kanske också gillar </h4>
      <div className="relatedPosts">
        {posts &&
          posts.map((post, index) => {
            
            const link = "/" + post.frontmatter.category + post.fields.slug
            const linkTo = link.replace(/ö/g, 'o').replace(/ä/g, 'a').replace(/å/g, 'a').replace(/ /g, '-').toLowerCase().toLowerCase()
          
            return (
              <Link to={linkTo} key={post.id} className="relatedLink">
                <div className="related">
                  <GatsbyImage image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData} className="relatedImage" alt={post.frontmatter.altText}/>
                  <h5 className="font-bold">{post.frontmatter.title}</h5>
                  <p>{new Date(post.frontmatter.date).toLocaleDateString('sv-SV', dateOptions)}</p>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
}
